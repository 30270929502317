/** @jsxImportSource @emotion/react */
import Color from 'color';
import Image from 'next/image';

import { lineClamp } from '@common/cssUtilities';
import { getLoaderWithAdditionalTransformations } from '@common/utlis';

import DrinkIcon from './icons/DrinkIcon.svg';
import EasyIcon from './icons/EasyIcon.svg';
import FoodIcon from './icons/FoodIcon.svg';
import MediumIcon from './icons/MediumIcon.svg';
import TimeIcon from './icons/TimeIcon.svg';

import type { Theme } from '@emotion/react';
import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import type { Interpolation } from '@emotion/serialize';

export interface IArticleProps {
  id: number;
  type: string;
  tags: ITagProps[];
  name: string;
  imageSrc: string;
  text: string;
  to: string;
  cssProps?: Interpolation<Theme>;
  onClick?: (Event) => void;
}
enum ETagType {
  Time = 'time',
  Easy = 'easy',
  Medium = 'medium',
  Drink = 'category',
  Food = 'food'
}
const TAG_VARIANT: Record<ETagType, ReactJSXElement> = {
  [ETagType.Drink]: <DrinkIcon />,
  [ETagType.Easy]: <EasyIcon />,
  [ETagType.Medium]: <MediumIcon />,
  [ETagType.Time]: <TimeIcon />,
  [ETagType.Food]: <FoodIcon />
};
interface ITagProps {
  label: string;
  type: string;
  id?: number;
}

const Tag: React.FC<ITagProps> = ({ label, type }) => {
  return (
    <div
      css={(t) => ({
        display: 'flex',
        border: `1px solid ${t.color.grey.g92}`,
        borderRadius: '100px',
        padding: '3px 8px',
        backgroundColor: t.color.grey.g97,
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '110%',
        whiteSpace: 'nowrap',
        svg: {
          height: '18px',
          width: '18px'
        }
      })}
    >
      {TAG_VARIANT[type] ?? <></>}
      <div css={(t) => ({ fontSize: t.font.size.footnote, marginLeft: '0.25rem', fontWeight: 'bold' })}>
        {label}
      </div>
    </div>
  );
};

const ArticleCard: React.FC<IArticleProps> = ({
  imageSrc,
  name,
  tags,
  text,
  type,
  to,
  onClick,
  cssProps
}) => {
  return (
    <a
      href={to}
      css={(t) => ({
        backgroundColor: Color(t.color.white).alpha(0.8).hsl().string(),
        border: `1px solid ${t.color.grey.g92}`,
        borderRadius: '8px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        ...(cssProps as object)
      })}
      onClick={onClick}
    >
      <div
        css={{
          aspectRatio: '2',
          position: 'relative'
        }}
      >
        <Image
          alt={name}
          fill
          loader={getLoaderWithAdditionalTransformations('ar_2,c_crop,w_800/')}
          sizes="(min-width: 1200px) 280px, 245px"
          src={imageSrc}
          css={() => ({
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
            height: 'auto',
            width: '100%'
          })}
        />
      </div>

      <div
        css={(t) => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          padding: '1rem 1rem 1.5rem',
          flexGrow: 1,
          fontFamily: t.font.family.copy,
          color: t.color.copy
        })}
      >
        <h3
          css={(t) => ({
            borderBottom: `1px solid ${t.color.grey.g92}`,
            fontWeight: 'bold',
            lineHeight: '125%',
            paddingBottom: '0.25rem',
            width: '100%'
          })}
        >
          {name.replaceAll('&amp;', '&')}
        </h3>
        <div
          css={(t) => ({
            padding: '1rem 0 0 0',
            ...lineClamp(5),
            ...t.font.small
          })}
        >
          {text}
        </div>
        {type === 'recipe' && (
          <div
            css={{
              columnGap: '.5rem',
              display: 'flex',
              justifyContent: 'flex-start',
              paddingTop: '1rem',
              width: '100%',
              marginTop: 'auto'
            }}
          >
            {tags?.map((tag) => <Tag {...tag} key={tag.label}></Tag>)}
          </div>
        )}
      </div>
    </a>
  );
};

export default ArticleCard;
