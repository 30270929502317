/** @jsxImportSource @emotion/react */
import { useRef } from 'react';

import { trackGASelectContent } from '@common/tracking';
import Button from '@stories/atoms/Button';
import { container } from '@stories/atoms/Grid';
import { SectionStyled } from '@stories/atoms/Section/Section';
import ArticleCard from '@stories/molecules/ArticleCard';
import Carousel from '@stories/organisms/Carousel';
import { media } from '@stories/theming/settings';

import type { BuyerGuideFragment } from '@gql/hygraph/graphql';
import type { IArticleProps } from '@stories/molecules/ArticleCard';

export type BuyerGuide = BuyerGuideFragment & { articles: IArticleProps[] };

const BuyerGuide: React.FC<BuyerGuide> = ({
  articles,
  cta,
  headline,
  shiftInfoCard,
  subheading,
  sectionStyle
}) => {
  const sectionRef = useRef(null);
  // useIntersection(sectionRef, () =>
  //   trackGA4InternalPromotionImpression({ ...trackingData, items: itemsForTracking })
  // );
  return (
    <SectionStyled {...sectionStyle} attrs={{ 'data-id': 'vitafy-buyer-guide' }}>
      <div
        css={{
          ...container,
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
          zIndex: 1,
          [media('desktop')]: {
            flexDirection: shiftInfoCard ? 'row-reverse' : 'row'
          }
        }}
      >
        <div
          ref={sectionRef}
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            [media('desktop')]: {
              width: '230px'
            }
          }}
        >
          <h2
            css={(t) => ({
              ...t.headline.h3
            })}
          >
            {headline}
          </h2>
          <div
            css={(t) => ({
              margin: '1rem 0 2rem 0',
              textAlign: 'left',
              ...t.font.small
            })}
          >
            {subheading}
          </div>
          {cta?.href && cta?.label && (
            <Button
              label={cta?.label ?? ''}
              href={cta?.href ?? ''}
              cssProps={{
                margin: '2rem 0 0',
                display: 'none',
                [media('desktop')]: {
                  display: 'flex'
                }
              }}
            />
          )}
        </div>
        <div
          css={{
            width: '100%',
            [media('desktop')]: { width: '75%' }
          }}
        >
          <Carousel seamless={true} slideGapCompensation={8} type={shiftInfoCard ? 'right' : 'left'}>
            {articles.map((element) => (
              <article
                key={element.id}
                css={{
                  padding: '0 .5rem',
                  width: '245px',
                  [media('desktop')]: {
                    width: '33.3%'
                  }
                }}
              >
                <ArticleCard
                  {...element}
                  cssProps={{ height: '100%' }}
                  onClick={() =>
                    trackGASelectContent({
                      contentType: element.type,
                      contentId: `${element.id} - ${element.name}`
                    })
                  }
                />
              </article>
            ))}
          </Carousel>
        </div>
        {cta?.href && cta?.label && (
          <Button
            label={cta.label}
            href={cta.href}
            cssProps={{
              margin: '2rem 0 0',
              display: 'flex',
              [media('desktop')]: {
                display: 'none'
              }
            }}
          />
        )}
      </div>
    </SectionStyled>
  );
};

export default BuyerGuide;
